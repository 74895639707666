<template>
  <div class="modal fade text-left" :id="modalName" tabindex="-1" role="dialog" :aria-labelledby="modalName"
       aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header" :class="`bg-${headerClass}`">
          <h5 class="modal-title white" id="">
            <slot name="modal-header">Header</slot>
          </h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <i class="bx bx-x"></i>
          </button>
        </div>
        <div class="modal-body">
          <div class="text-center mx-2">
            <i style="font-size: 4rem;" class='bx mx-2' :class="`text-${headerClass} ${boxIconClass}`"></i>
            <br>
          </div>
          <div class="text-center mx-2">
            <slot name="modal-body">Do you really want to do this action? <br> This process can't be undone.</slot>
          </div>
        </div>
        <div class="modal-footer bg-lighten-4 d-flex justify-content-lg-between">
          <button v-show="isCancelShow" type="button" class="btn btn-sm btn-danger" data-dismiss="modal"
                  @click="confirmModel(status= false)">
            <i class="bx bx-x d-block d-sm-none"></i>
            <span class="d-none d-sm-block">{{ cancelTitle }}</span>
          </button>
          <button type="button" class="btn btn-sm btn-success ml-1" data-dismiss="modal"
                  @click="confirmModel(status = true)">
            <i class="bx bx-check d-block d-sm-none"></i>
            <span class="d-none d-sm-block">{{ okTitle }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ConfirmAlertModal",
  emits: ['confirmActionModel'],
  props: {
    okOnly: {
      type: Boolean,
      default: false,
    },
    modalName: {
      type: String,
      default: "actionModal",
      required: true,
    },
    headerClass: {
      type: String,
      default: "warning",
    },
    modelName: {
      type: String,
      default: "",
      required: true,
    },
    okTitle: {
      type: String,
      default: "Ok",
    },
    cancelTitle: {
      type: String,
      default: "Cancel",
    },
    modelId: {
      type: [Number, String],
      required: true
    },
    bodyIcon: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      status: null,
      isCancelShow: true,
      boxIconClass: 'bx-check-circle'
    }
  },
  watch: {
    okOnly(value) {
      console.log(value);
      this.isCancelShow = !value;
    },
    bodyIcon(value) {
      this.boxIconClass = value === 'ok' ? 'bx-check-circle' : 'bx-x-circle';
    }
  },
  methods: {
    confirmModel() {
      this.$emit('confirmActionModel', {
        modelId: this.modelId,
        modelName: this.modelName,
        status: this.status,
      });
    },
  }
}
</script>

<style scoped>

</style>