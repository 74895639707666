<template>
  <AppLayout>
    <template v-slot:appContent>

      <section class="users-list-wrapper">
        <div class="d-sm-flex justify-content-between align-items-center pt-2 pt-md-2 pt-xl-0 ">
          <h4 class="">Cash Payments/List</h4>
        </div>
        <br>
        <div class="users-list-filter  px-1 my-2 py-2 border rounded">
          <div class="row">
            <div class="col-12 col-sm-6 col-md-4 col-lg-3">
              <div class="form-group">
                <div class="">
                  <label>Search Appointment</label>
                  <input type="text" class="form-control" placeholder="Search an appointment..." value=""
                         name="firstName">
                </div>
              </div>

            </div>
            <div class="col-12 col-sm-6 col-md-4 col-lg-3">
              <div class="form-group">
                <div class="controls">
                  <label>Technician </label>
                  <VueMultiselect v-model="technicalValue" class="multiselect-blue" :options="technical"
                                  :close-on-select="true" placeholder="Select a technician" label="name" track-by="name"
                                  :show-labels="false"/>
                </div>

              </div>

            </div>
            <div class="col-12 col-sm-6 col-md-4 col-lg-3">
              <div class="form-group">
                <div class="controls">
                  <label>Admin </label>
                  <VueMultiselect v-model="technicalValue" class="multiselect-blue" :options="technical"
                                  :close-on-select="true" placeholder="Select a admin" label="name" track-by="name"
                                  :show-labels="false"/>
                </div>

              </div>
            </div>
            <div class="col-12 col-sm-6 col-md-2 col-lg-3">
              <div class="form-group">
                <label>Start date</label>
                <div class="d-flex align-items-center date-picker-wrapper">
                  <div class="d-flex align-items-center">
                    <i class='bx bx-calendar'></i>
                  </div>
                  <div class="w-100 pl-1 ">
                    <div class="w-100 ">
                      <DatePicker v-model="startDate" :update-on-input="true"
                                  :masks="{input: ['DD MMMM YYYY']}"
                                  :model-config="{ type: 'string', mask: 'YYYY-MM-DD'}">
                        <template v-slot="{ inputValue, inputEvents }">
                          <input class="form-control" :value="inputValue" v-on="inputEvents"/>
                        </template>
                      </DatePicker>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-6 col-md-2  col-lg-3">
              <div class="form-group">
                <label>End date</label>
                <div class="d-flex align-items-center date-picker-wrapper">
                  <div class="d-flex align-items-center">
                    <i class='bx bx-calendar'></i>
                  </div>
                  <div class="w-100 pl-1 ">
                    <div class="w-100 ">
                      <DatePicker v-model="endDate" :update-on-input="true"
                                  :masks="{input: ['DD MMMM YYYY']}"
                                  :model-config="{ type: 'string', mask: 'YYYY-MM-DD'}">
                        <template v-slot="{ inputValue, inputEvents }">
                          <input class="form-control" :value="inputValue" v-on="inputEvents"/>
                        </template>
                      </DatePicker>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-6 col-md-4 col-lg-3">
              <div class="form-group">
                <div class="controls">
                  <label>Payment status </label>
                  <VueMultiselect v-model="paymentValue" class="" :options="payment" :close-on-select="true"
                                  placeholder="Select a status" label="name" track-by="name" :show-labels="false"/>
                </div>

              </div>

            </div>
            <div class="col-12 col-sm-6 col-md-4 col-lg-3">
              <div class="form-group">
                <div class="controls">
                  <label>Handover status</label>
                  <VueMultiselect @select="onSelect" v-model="handoverValue" class="multiselect-blue"
                                  :options="handover" :close-on-select="true" placeholder="Select Handover status"
                                  label="name" track-by="language" :show-labels="false"/>
                </div>

              </div>

            </div>
            <div class="col-12 col-sm-6 col-md-4 col-lg-3">
              <div class="form-group">
                <div class="controls">
                  <label></label>
                  <button @click="applyPaymentsFilter(null)" type="reset"
                          class="btn btn-primary btn-block glow users-list-clear mb-0">Search
                  </button>

                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="users-list-table">
          <div class="card">
            <div class="card-body">
              <div class="table-responsive">
                <table id="users-list-datatable" class="table">
                  <thead>
                  <tr>
                    <th class="position-relative" :style="{width:'20%'}">CUSTOMER</th>
                    <th class="position-relative" :style="{width:'12%'}">PAY SECTION</th>
                    <th class="position-relative" :style="{width:'12%'}">TECHNICIAN</th>
                    <th class="position-relative" :style="{width:'12%'}">TYPE</th>
                    <th class="position-relative" :style="{width:'10%'}">STATUS</th>
                    <th class="position-relative" :style="{width:'10%'}">PRICE</th>
                    <th class="position-relative" :style="{width:'10%'}">ACTIONS</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(collection) in cashPayments" :key="collection.id">
                    <td>
                      <div v-if="collection.paidBy && collection.paidBy !== null">
                        <p class="mb-0 text-secondary text-capitalize">
                          {{ `${collection.paidBy.first_name} ${collection.paidBy.last_name}` }}</p>
                        <div>
                          <small>{{ userFullAddress(collection.paidBy.customer) }}</small>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div>
                        <p class="mb-0 text-secondary">
                          {{ collection.payment.appointmentPayment === null ? 'Order' : 'Appointment' }}</p>
                        <div>
                          <small>{{ `Paid by Cash` }}</small>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div v-if="collection.paidTo && collection.paidTo !== null">
                        <p class="mb-0 text-secondary text-capitalize">
                          {{ `${collection.paidTo.first_name} ${collection.paidTo.last_name}` }}</p>
                        <div>
                          <small>{{ collection.code }}</small>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div>
                        <p class="mb-0 badge badge-success badge-pill">CASH</p>
                      </div>
                    </td>
                    <td>
                      <div>
                        <p class="mb-0 badge badge-pill"
                           :class="collection.handover_status === 'Open'?'badge-info':
                           (collection.handover_status ==='Pending'?'badge-warning':
                           (collection.handover_status ==='HandOvered'?'badge-success':'badge-danger'))">
                          {{ collection.handover_status }}</p>
                      </div>
                    </td>
                    <td>
                      <div>
                        <p class="mb-0 text-secondary"> $ {{ parseFloat(collection.amount / 100).toFixed(2) }}</p>
                      </div>
                    </td>

                    <td>

                      <div class="d-flex align-items-top justify-content-around">
                        <div @click="getCashTrackingFromObj(collection.id)" data-toggle="modal"
                             data-target="#cashPaymentDetailsModal"
                             class="cursor-pointer view-edit-delte-icon"
                             :class="!can('cash-payment-view') ? 'disable-event' : ''" :style="{height:'18px'}">
                          <i class='bx bx-show'></i>
                        </div>
                        <!-- <div class="text-decoration-none cursor-pointer view-edit-delte-icon">
                          <div class=" " data-toggle="modal" data-target="#deleteModal" :style="{height:'18px'}">
                            <i class='bx bx-x'></i>
                          </div>
                        </div> -->

                        <div class="dropdown">
                          <span type="button"
                                class="px-0 py-0 view-edit-delte-icon"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false">
                            <i class='bx bx-dots-vertical-rounded h-100'></i>
                          </span>
                          <div class="dropdown-menu">
                            <!--                            <button class="dropdown-item" data-toggle="modal"
                                                                data-target="#cashPaymentsShortDetailsModal">
                                                          View Cash Payment Details
                                                        </button>-->
                            <!--                            <button class="dropdown-item" data-toggle="modal" data-target="#updateHandOverModal">
                                                          Update HandOver
                                                        </button>-->
                            <button @click="selectSingleCash(collection.id)"
                                    :disabled="(collection.handover_status ==='Pending' && collection.handoverTo.id === authUser.id? false: true) && !can('cash-payment-update')"
                                    class="dropdown-item"
                                    data-toggle="modal"
                                    data-target="#cashAcceptModal">
                              Accept Cash Request
                            </button>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <ListPagination position="right" :pagination-data="paginateLinks" @getClickedPage="applyPaymentsFilter"/>
            </div>
          </div>
        </div>

        <!-- customer details modal -->
        <!-- <CustomerDetails :currentCustomerDetails="currentCustomerDetails" /> -->
        <confirm-alert-modal :model-id="selectedCashObj.id" cancel-title="Decline" ok-title="Accept"
                             model-name="cash"
                             modal-name="cashAcceptModal" header-class="success" ok-only
                             @confirmActionModel="onConfirmActionModel">
          <template v-slot:modal-header> Cash Request</template>
          <template v-slot:modal-body>Do you want to accept cash request
            $ {{ parseFloat(selectedCashObj.handover_amount / 100).toFixed(2) }} to
            $ {{ parseFloat(selectedCashObj.amount / 100).toFixed(2) }}
          </template>
        </confirm-alert-modal>

        <PaymentDetailsModal :details-cash-payment="singleCashTrackingObj"/>
        <CashPaymentsShortDetailsModal :details-cash-payment="singleCashTrackingObj"/>
        <UpdateHandOverModal/>
      </section>
    </template>
  </AppLayout>
</template>

<script>
import AppLayout from '@/layouts/backEnd/AppLayout.vue'

import ListPagination from "@/components/backEnd/pagination/ListPagination";

import PaymentDetailsModal
  from "@/views/backEnd/AccountAndFinance/CashTracking/includes/PaymentDetailsModal/PaymentDetailsModal";
import UpdateHandOverModal
  from "@/views/backEnd/AccountAndFinance/CashTracking/includes/UpdateHandOverModal/UpdateHandOverModal";
import {mapActions, mapGetters} from "vuex";
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";
import ConfirmAlertModal from "@/components/backEnd/modal/ConfirmAlertModal";
import {DatePicker} from "v-calendar";
import CashPaymentsShortDetailsModal
  from "@/views/backEnd/AccountAndFinance/CashTracking/includes/CashPaymentsDetailsModal/CashPaymentsShortDetailsModal";
import Authorization from "@/components/backEnd/mixins/Authorization";

export default {
  name: "CashTracking",
  mixins: [ShowToastMessage, Loader, Authorization],
  components: {
    UpdateHandOverModal,
    DatePicker,
    PaymentDetailsModal,
    AppLayout,
    CashPaymentsShortDetailsModal,
    ConfirmAlertModal,
    ListPagination,
  },
  data() {
    return {
      onSelect: {
        value: '',
        name: 'None',
      },

      paymentValue: {
        name: 'Any ',
        language: 'Any '
      },
      handoverValue: {
        name: 'Any ',
        language: 'Any '
      },
      payment: [
        {
          name: 'Paid',
          language: 'Paid'
        },
        {
          name: 'Unpaid',
          language: 'Unpaid'
        },

      ],
      handover: [
        {
          name: 'Open',
          language: 'Open'
        },
        {
          name: 'Handed',
          language: 'Handed'
        },

      ],
      imgSource: '',
      technicalValue: {
        name: 'Any ',
        language: 'Any '
      },
      adminValue: {
        name: 'Any ',
        language: 'Any '
      },
      statusVlaue: {
        name: 'Any ',
        language: 'Any '
      },
      status: [
        {
          name: 'Open',
          language: 'Open'
        },
        {
          name: 'Hold',
          language: 'Hold '
        },
        {
          name: 'Rescheduled',
          language: 'Rescheduled'
        },
        {
          name: 'Delivered',
          language: 'Delivered'
        },
        {
          name: 'Closed',
          language: 'Closed'
        },
        {
          name: 'Dispute',
          language: 'Dispute'
        },

      ],

      technical: [
        {
          name: 'Wahid S',
          language: 'Wahid S'
        },
        {
          name: 'Zubi J',
          language: 'Zubi J '
        },
        {
          name: 'Luis C ',
          language: 'Luis C J'
        },
        {
          name: 'Oshinne ',
          language: 'Oshinne '
        },
        {
          name: 'Sandip KC ',
          language: 'Sandip KC '
        },
        {
          name: 'Any ',
          language: 'Any '
        },

      ],
      admin: [
        {
          name: 'Wahid S',
          language: 'Wahid S'
        },
        {
          name: 'Zubi J',
          language: 'Zubi J '
        },
        {
          name: 'Luis C ',
          language: 'Luis C J'
        },
        {
          name: 'Oshinne ',
          language: 'Oshinne '
        },
        {
          name: 'Sandip KC ',
          language: 'Sandip KC '
        },
        {
          name: 'Any ',
          language: 'Any '
        },

      ],

      picked: new Date(),
      customerTypeSelectedValue: '',
      statusSelectedValue: '',
      customerType: [
        {
          name: 'Home',
          language: 'Home'
        },
        {
          name: 'Business',
          language: 'Business'
        },
        {
          name: 'Any ',
          language: 'Any '
        },

      ],


      currentCustomerDetails: {
        id: "",
        name: "",
        address: "",
        type: "",
        businessName: "",
        tech: "",
        status: "",
        newsletter: ""
      },
      getSettingsParams: {
        type: ['default'],
        key: ['default_pagination', 'default_state', 'default_customer_type'],
      },
      getCashPaymentsParams: {
        with_relation: ['handoverTo', 'paidBy.customer.address.business', 'paidTo', 'payment.appointmentPayment', 'payment.orderPayment'],
        paginate: 1,
        pagination: '',
        page: ''
      },
      getCashPaymentParams: {},
      selectedCashId: '',
      selectedCashObj: {
        id: ''
      },
      startDate: '',
      endDate: '',
      singleCashTrackingObj: {}

    }
  },
  watch: {},
  computed: {
    ...mapGetters({
      cashPayments: 'appCashPayments/cashPayments',
      paginateLinks: 'appCashPayments/paginateLinks',
      authUser: 'appAuthLogin/authUser'
    }),

    paginationOptions() {
      return [{
        value: this.$store.getters['appSettings/settingDefaultPagination'].value,
        name: 'Default'
      }, {
        value: 25,
        name: '25 Entries'
      }, {
        value: 50,
        name: '50 Entries'
      }, {
        value: 100,
        name: '100 Entries'
      }];
    },

  },
  methods: {
    ...mapActions({
      getCashPayments: 'appCashPayments/getCashPayments',
      getCashPayment: 'appCashPayments/getCashPayment',
      putCashPayment: 'appCashPayments/putCashPayment',
      getSettings: 'appSettings/getSettings',
    }),
    selectSingleCash(cashId) {
      this.selectedCashObj = this.cashPayments.find(({id}) => id === cashId);
    },
    async updateCashTrackingHandoverStatus(primaryId, status) {
      let today = new Date();
      let date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
      let dataObj = {
        id: primaryId,
        data: {
          amount: this.selectedCashObj.amount,
          handover_amount: this.selectedCashObj.handover_amount,
          handover_status: status,
          handover_date: date,
        },
      };
      await this.putCashPayment(dataObj).then(async (response) => {
        if (response.status === 200) {
          let toastObj = {message: 'Cash Handover Info Updated', type: 'success'};
          this.showToastMessage(toastObj);
          return;
        }
        this.showToastMessage(response);
      });
    },
    handoverStatusEmailNotify(paymentId) {
      const data = {
        id: paymentId,
        params: {
          notify_internal_user: 1,
          notify_handed_to: 1,
        }

      };

      this.postOrderCreationNotifyEmail(data);
    },

    async onConfirmActionModel(confirmActionModel) {
      if (confirmActionModel.status !== null) {
        let id = confirmActionModel.modelId;
        let handover_status = confirmActionModel.status === true ? 2 : 3// 2 for accept,  3 for declined
        await this.updateCashTrackingHandoverStatus(id, handover_status);
        let cashPayment = this.$store.getters['appCashPayments/cashPayment'];
        let index = this.cashPayments.findIndex((cash) => cash.id === id);
        if (index !== -1) {
          this.cashPayments[index].handover_status = cashPayment.handover_status;
        }
      }
      console.log(confirmActionModel);
    },

    async getCashPaymentList(paymentsParams) {
      this.loader(true);
      await this.getCashPayments(paymentsParams)
          .then((response) => {
            if (response && response.message && response.status !== 200) {
              this.loader(false);
              this.showToastMessage(response);
            } else {
              this.loader(false);
            }
          });
    },
    async getSingleCashPayment(id) {
      const paramObj = {
        id: id,
        params: this.getCashPaymentParams,
      };
      await this.getCashPayment(paramObj)
          .then((response) => {
            if (response && response.status && response.status === 200) {
              this.cashPayment = this.$store.getters['appCashPayments/cashPayment'];
            }

            if (response && response.message && response.status !== 200) {
              this.showToastMessage(response);
            }

          });
    },

    async getSettingList(settingParams) {
      await this.getSettings(settingParams);
    },
    async applyPaymentsFilter(pageNumber) {
      this.getCashPaymentsParams.page = pageNumber;
      await this.getCashPaymentList(this.getCashPaymentsParams);
    },
    userFullAddress(customerObj) {
      let businessName = customerObj.address?.business?.name ?? '';
      let addressStreet = customerObj.address.street;
      let addressSuburb = customerObj.address.suburb;
      let addressState = customerObj.address.state.toUpperCase();
      let addressPostCode = customerObj.address.post_code;
      let addressCountry = customerObj.address.country;
      return customerObj.type === 'Business' ?
          `${businessName} (${addressStreet}, ${addressSuburb} ${addressState} ${addressPostCode}, ${addressCountry})` :
          `${addressStreet}, ${addressSuburb} ${addressState} ${addressPostCode}, ${addressCountry}`;
    },
    async getCashTrackingFromObj(cashId) {
      this.singleCashTrackingObj = await this.cashPayments.find(({id}) => id === cashId);
    },
  },
  async mounted() {
    await this.loader(true);
    await this.getSettingList(this.getSettingsParams);
    await this.getCashPaymentList(this.getCashPaymentsParams);
    await this.loader(false);
  }

}
</script>

<style scoped>
.badge {
  display: inline-block;
  padding: 4px 8px !important;
  font-size: 10px !important;
  font-weight: 400;
  line-height: 1 !important;
  text-align: center !important;
  white-space: nowrap !important;
  vertical-align: baseline !important;
  border-radius: 0.375rem !important;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}
</style>
