<template>
  <div class="modal fade " id="cashPaymentDetailsModal" tabindex="-1" role="dialog"
       aria-labelledby="productDetailsModal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable modal-lg modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header bg-primary">
          <h1 class="modal-title white">Cash Payment Details</h1>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <i class="bx bx-x"></i>
          </button>
        </div>
        <div class="modal-body">
          <div class="table-responsive">
            <table class="table">
              <tbody>
              <tr>
                <th class="text-center pb-0">
                  Payment Details (#{{ detailsCashPayment?.payment?.code ?? '' }})
                </th>
              </tr>
              <tr>
                <td>
                  <p><strong>Technician: </strong>
                    {{
                      `${detailsCashPayment?.paidTo?.first_name ?? ''} ${detailsCashPayment?.paidTo?.last_name ?? ''}`
                    }}(VIC)
                  </p>
                  <span><strong>Pay Section: </strong></span><br>
                  <span><strong>Amount: </strong>${{
                      parseFloat(detailsCashPayment.amount / 100).toFixed(2)
                    }}</span><br>
                  <span><strong>Cash Payment: </strong>${{
                      parseFloat(detailsCashPayment.amount / 100).toFixed(2)
                    }}</span><br>
                  <span>
                                        <strong>Pay Status: </strong>
                                        <span class="badge badge-completed font-weight-bold text-capitalize">Paid</span>
                                    </span><br>
                  <!--                  <span>
                                      <strong>Note: </strong>
                                    </span>-->
                </td>
              </tr>
              </tbody>
            </table>
            <table class="table">
              <tbody>
              <tr>
                <th class="text-center pb-0">
                  Customer Details
                </th>
              </tr>
              <tr>
                <td>
                  <p>
                    <strong>Customer: </strong>
                    {{
                      `${detailsCashPayment?.paidBy?.first_name ?? ''} ${detailsCashPayment?.paidBy?.last_name ?? ''}`
                    }}
                  </p>
                  <span><strong>Email: </strong>{{ detailsCashPayment?.paidBy?.email ?? 'Not Provided' }}</span><br>
                  <span><strong>Phone: </strong>{{
                      detailsCashPayment?.paidBy?.phone_number ?? 'Not Provided'
                    }}</span><br>
                  <span><strong>Address: </strong>{{
                      userFullAddress(detailsCashPayment?.paidBy?.customer ?? {})
                    }}</span>
                </td>
              </tr>
              </tbody>
            </table>
            <table class="table">
              <tbody>
              <tr>
                <th class="text-center pb-0">
                  Handed Details
                </th>
              </tr>
              <tr>
                <td>
                  <strong>Status: </strong><span
                    class="badge badge-info">{{ detailsCashPayment.handover_status }}</span>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PaymentDetailsModal",
  props: ['detailsCashPayment'],
  data() {
    return {}
  },
  methods: {
    userFullAddress(customerObj) {
      let businessName = customerObj.address?.business?.name ?? '';
      let addressStreet = customerObj.address?.street;
      let addressSuburb = customerObj.address?.suburb;
      let addressState = customerObj.address?.state.toUpperCase();
      let addressPostCode = customerObj.address?.post_code;
      let addressCountry = customerObj.address?.country;
      return customerObj?.type === 'Business' ?
          `${businessName} (${addressStreet}, ${addressSuburb} ${addressState} ${addressPostCode}, ${addressCountry})` :
          `${addressStreet}, ${addressSuburb} ${addressState} ${addressPostCode}, ${addressCountry}`;
    },
  }

}
</script>

<style scoped>
p {
  margin-bottom: 0px;
}

</style>
