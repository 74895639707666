<template>
<datepicker placeholder="select date"  :clearable="true" class="picker"  v-model="picked" />

</template>

<script>

import {ref} from 'vue';
export default {
    name:"Picker2",
    components: {
        
    },
    setup() {
        const picked = ref()
        return {
            picked
        }
    }
}
</script>

<style >

.v3dp__input_wrapper > input {
    width: 100% !important;
    border: none;

} 
input:focus {
    border: none;
    outline: none;
}
/* .v3dp__elements button.selected span[data-v-2e128338] {
    background-color: #5A8DEE;
    color: white;
} */

</style>
