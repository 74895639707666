<template>
<div class="modal fade " id="updateHandOverModal" tabindex="-1" role="dialog" aria-labelledby="productDetailsModal" aria-hidden="true">
    <div class="modal-dialog  modal-md modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header bg-primary">
                <h1 class="modal-title white"></h1>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <i class="bx bx-x"></i>
                </button>
            </div>
            <div class="modal-body">
                <div>
                    <div class="row">
                        <div class="col-12">
                            <div class="form-group">
                                <div class="controls">
                                    <label for="">Handover By </label>
                                    <VueMultiselect v-model="technicalValue" class="multiselect-blue" :options="technical" :close-on-select="true" placeholder="Select" label="name" track-by="name" :show-labels="false" />
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="form-group">
                                <div class="controls">
                                    <label for="">Handover To*</label>
                                    <VueMultiselect :v-model="technicalValue" class="multiselect-blue" :options="technical" :close-on-select="true" placeholder="Select" label="name" track-by="name" :show-labels="false" />
                                </div>
                            </div>

                        </div>
                        
                        <div class="col-12">
                            <div class="form-group">
                                <div class="controls">
                                    <label>Handover Date</label>
                                    <div class="d-flex form-control align-items-center date-picker-wrapper">
                                        <div class="d-flex align-items-center">
                                            <i class='bx bx-calendar'></i>
                                        </div>
                                        <div class="w-100 pl-1">
                                            <div class="w-100 ">
                                                <Picker2></Picker2>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                        <div class="col-12">
                            <div class="form-group">
                                <div class="controls">
                                    <label for="">Handover Amount ($)</label>
                                    <input type="number" class="form-control" placeholder="" value="" name="standardUnitPrice">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div class="modal-footer border-0 pt-0 pr-0">
                                <button type="button" class="btn btn-primary" data-dismiss="modal">
                                    <i class="bx bx-x d-block d-sm-none"></i>
                                    <span class="d-none d-sm-block">Save Changes</span>
                                </button>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import Picker2 from "@/views/backEnd/appointments/includes/Picker2";


export default {
    name: "UpdateHandOverModal",
  components:{
    Picker2
  },
    data() {
        return {
            technicalValue: '',
            technical: [{
                    name: 'Wahid S',
                    language: 'Wahid S'
                },
                {
                    name: 'Zubi J',
                    language: 'Zubi J '
                },
                {
                    name: 'Luis C ',
                    language: 'Luis C J'
                },
                {
                    name: 'Oshinne ',
                    language: 'Oshinne '
                },
                {
                    name: 'Sandip KC ',
                    language: 'Sandip KC '
                },
                {
                    name: 'Any ',
                    language: 'Any '
                },

            ],
        }
    }

}
</script>

<style>

</style>
