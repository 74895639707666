<template>
  <div class="modal fade " id="cashPaymentsShortDetailsModal" tabindex="-1" role="dialog"
       aria-labelledby="productDetailsModal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable modal-lg modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header bg-primary">
          <h1 class="modal-title white">Payment Cash Pay info</h1>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <i class="bx bx-x"></i>
          </button>
        </div>
        <div class="modal-body">
          <div class="table-responsive">
            <table class="table">
              <tbody>
              <tr>
                <th>SL</th>
                <th>Pay Section</th>
                <th>Pay Type</th>
                <th class="text-right">Amount</th>
                <th>Date &amp; Time</th>
              </tr>
              <tr>
                <td>1</td>
                <td>appointment</td>
                <td>cash</td>
                <td class="text-right">$171.60</td>
                <td>20 Dec 2021, 3:03 PM</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CashPaymentsDetailsModal",
  props: ['detailsCashPayment'],
  data() {
    return {}
  }

}
</script>

<style scoped>
p {
  margin-bottom: 0;
}
</style>
